<template>
  <v-dialog v-model="dialog" width="450px">
    <v-card color="teal lighten-5">
      <v-card-title class="teal lighten-5"> </v-card-title>

      <v-card-text>
        <div class="d-flex">
          <v-sheet
            color="light-blue darken-4"
            elevation="1"
            width="150"
            height="40"
            class="text-center d-flex flex-column align-center justify-center"
            dark
            @click="ChangeBetType('1')"
            >บน</v-sheet
          >
          <v-sheet
            color="deep-orange darken-4"
            elevation="1"
            width="150"
            height="40"
            class="text-center d-flex flex-column align-center justify-center"
            dark
            @click="ChangeBetType('2')"
            >ล่าง</v-sheet
          >
          <v-sheet
            color="teal darken-4"
            elevation="1"
            width="150"
            height="40"
            class="text-center d-flex flex-column align-center justify-center"
            dark
            @click="ChangeBetType('18')"
            >3 ตัวหน้า</v-sheet
          >
        </div>
        <div v-for="n in 10" v-bind:key="n">
          <v-divider></v-divider>
          <div class="d-flex">
            <v-sheet
              color="light-blue lighten-5"
              elevation="1"
              width="150"
              height="40"
              class="text-center font-weight-bold d-flex flex-column align-center justify-center"
              @click="ChangeBetType(top_items[n - 1].value)"
              >{{ top_items[n - 1].text }}</v-sheet
            >
            <v-sheet
              color="brown lighten-5"
              elevation="1"
              width="150"
              height="40"
              class="text-center font-weight-bold d-flex flex-column align-center justify-center"
              @click="ChangeBetType(down_items[n - 1].value)"
              >{{ down_items[n - 1].text }}</v-sheet
            >
            <v-sheet
              color="teal lighten-5"
              elevation="1"
              width="150"
              height="40"
              class="text-center font-weight-bold d-flex flex-column align-center justify-center"
              @click="ChangeBetType(front_items[n - 1].value)"
              >{{ front_items[n - 1].text }}</v-sheet
            >
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  methods: {
    Show(BtType) {
      this.dialog = true;
      this.BtType = BtType;
    },
    ChangeBetType(BtType) {
      if (BtType == "0") return;
      this.$emit("OnChangeBetType", BtType);
      this.dialog = false;
    },
  },
  data: () => ({
    dialog: false,
    BtType: "1",
    top_items: [
      { text: "บน+ล่าง", value: "3" },
      { text: "ลอยบน", value: "13" },
      { text: "คู่โต๊ด", value: "17" },
      { text: "พวง", value: "4" },
      { text: "19 หาง", value: "15" },
      { text: "คู่หน้า", value: "11" },
      { text: "คู่ถ่าง", value: "12" },
      { text: "จมหน้า", value: "6" },
      { text: "จมกลาง", value: "7" },
      { text: "จมท้าย", value: "8" },
    ],

    down_items: [
      { text: "ล่าง+บน", value: "3" },
      { text: "ลอยล่าง", value: "14" },
      { text: "3 ตัวล่าง", value: "5" },
      { text: "19 หาง", value: "16" },
      { text: "จมหน้า", value: "9" },
      { text: "จมท้าย", value: "10" },
      { text: "", value: "0" },
      { text: "", value: "0" },
      { text: "", value: "0" },
      { text: "", value: "0" },
    ],

    front_items: [
      { text: "3 บน", value: "18" },
      { text: "2 บน", value: "18" },
      { text: "คู่โต๊ด", value: "26" },
      { text: "พวง", value: "19" },
      { text: "ลอยบน", value: "25" },
      { text: "คู่หน้า", value: "23" },
      { text: "คู่ถ่าง", value: "24" },
      { text: "จมหน้า", value: "20" },
      { text: "จมกลาง", value: "21" },
      { text: "จมท้าย", value: "22" },
    ],
  }),
};
</script>

<style></style>
