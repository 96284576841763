<template>
  <div>
    <v-menu
      v-model="showMenu"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
      :nudge-width="150"
    >
      <v-list dense>
        <v-list-item link>
          <v-list-item-icon>
            <v-icon color="blue">mdi-lead-pencil</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>แก้ไข</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item
          v-if="ShowSendBack"
          link
          @click="menuActionClick('send_back')"
        >
          <v-list-item-icon>
            <v-icon color="deep-purple accent-4"
              >mdi-arrow-u-down-left-bold</v-icon
            >
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="purple--text">{{
              getSendBackText()
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider v-if="ShowSendBack"></v-divider>

        <v-list-item link @click="menuActionClick('del')">
          <v-list-item-icon>
            <v-icon color="red">mdi-delete-forever</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="red--text">ลบ</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>

        <v-list-item link @click="deleteItem()">
          <v-list-item-icon>
            <v-icon color="light-blue darken-4">mdi-percent-box</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>อั้น</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>

        <v-list-item link @click="menuActionClick('page')">
          <v-list-item-icon>
            <v-icon color="success">mdi-file</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>ดูรายละเอียด</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "PopupMenu",

  methods: {
    Show(id, page_status, line_status, line_text) {
      this.showMenu = true;
      this.id = id; //LineNumber
      this.PageStatus = page_status;
      this.LineStatus = line_status;
      this.LineText = line_text;

      if (this.PageStatus == -1 || this.PageStatus == 0)
        this.ShowSendBack = false;
      else if (this.LineStatus == 1) this.ShowSendBack = false;
      else this.ShowSendBack = true;
    },
    SetPageNumber(PageNumber) {
      this.PageNumber = PageNumber;
    },
    SetAgentID(AgentID) {
      this.AgentID = AgentID;
    },
    SetLineID(LineID) {
      this.LineID = LineID;
    },
    Hide(x, y) {
      this.x = x;
      this.y = y;
    },
    getSendBackText() {
      if (this.LineStatus == 4) return "เรียกคืน";
      return "ตีกลับ";
    },
    menuActionClick(menu) {
      switch (menu) {
        case "del":
          this.$confirm("กดยืนยันเพื่อ ลบ บรรทัดที่ " + this.LineText)
            .then(() => {
              this.$emit("del", this.id);
            })
            .catch(() => {});
          break;
        case "send_back":
          if (this.LineStatus == 4) {
            this.$confirm("กดยืนยันเพื่อ เรียกคืน บรรทัดที่ " + this.LineText)
              .then(() => {
                this.$emit("resend", this.id);
              })
              .catch(() => {});
          } else {
            this.$confirm("กดยืนยันเพื่อ ตีกลับ บรรทัดที่ " + this.LineText)
              .then(() => {
                this.$emit("send_back", this.id);
              })
              .catch(() => {});
          }

          break;
        case "page":
          this.$router.push({
            path: "page",
            query: {
              line_id: this.LineID,
              agent_id: this.AgentID,
              page_number: this.id,
            },
          });

          //this.$router.push("/Agent_Data");
          break;
      }
    },
  },
  data: () => ({
    id: null,
    LineID: null,
    AgentID: null,
    PageNumber: null,
    LineStatus: 0,
    PageStatus: 0,
    LineText: null,
    ShowSendBack: true,
    showMenu: false,
    x: 0,
    y: 0,

    menu_items: [
      { text: "แก้ไข", icon: "mdi-lead-pencil", value: 0 },
      { text: "ลบ", icon: "mdi-delete-forever", value: 0 },
      { text: "อั้น/ปลดอั้น", icon: "", value: 0 },
      { text: "ตีกลับ", icon: "", value: 0 },
      { text: "ดูรายละเอียด", icon: "mdi-file", value: 0 },
    ],
  }),
};
</script>

<style></style>
