<template>
  <v-col
    style="-moz-user-select: none; -webkit-user-select: none; -ms-user-select:none; user-select:none;-o-user-select:none;"
    unselectable="on"
    onselectstart="return false;"
    onmousedown="return false;"
    cols="12"
    class="ma-0 pa-0"
  >
    <!-- :headers="headers" -->
    <v-data-table
      :items="page_data"
      :items-per-page="10"
      item-key="LineNumber"
      single-select
      class="elevation-1 add_table"
      hide-default-footer
      disable-pagination
      mobile-breakpoint="0"
      height="35vh"
    >
      <template v-slot:item="{ item }">
        <tr
          @contextmenu="popup_menu($event, item)"
          @click="row_click(item)"
          :class="{ 'purple lighten-4': item.LineNumber === selectedId }"
        >
          <!-- <td :class="id_column">{{ item.id }}</td> -->
          <td align="end" :class="id_column" class="pl-1 pr-2">
            {{ item.LineNumber }}.
          </td>
          <td
            align="center"
            class=" pl-1 pr-2"
            :class="[border_class, getBetTypeColor(item.BetTypeID)]"
          >
            {{ getBetTypeName(item.BetTypeID) }}
          </td>
          <td align="end" class="pl-1 pr-2">
            {{ item.Number }}
          </td>
          <td align="end" class="pl-1 pr-2 indigo--text" :class="border_class">
            &nbsp;=
          </td>
          <td
            align="end"
            class="pl-1 pr-2"
            :class="getPriceColor(getDirectPrice(item.PriceText))"
          >
            {{
              getDirectPrice(item.PriceText)
                .toString()
                .replace(/\B(?!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
            }}
          </td>
          <td
            align="end"
            class="pl-1 pr-0"
            :class="getSignColor(getSign(item.PriceText))"
          >
            {{ getSign(item.PriceText) }}
          </td>
          <td
            align="end"
            class="pl-1 pr-2"
            :class="[border_class, getPriceColor(getSwapPrice(item.PriceText))]"
          >
            {{
              getSwapPrice(item.PriceText)
                .toString()
                .replace(/\B(?!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
            }}
          </td>
        </tr>
      </template>

      <template slot="body.append">
        <!-- v-if="!isTouchScreendevice()" -->
        <tr>
          <td align="end" :class="id_column_new" class="pl-1 pr-2">
            {{ Input.ShowLine }}.
          </td>
          <td
            align="center"
            class="pl-1 pr-2 c_bettype"
            :class="[border_class, getBetTypeColor(Input.BtType)]"
          >
            {{ getBetTypeName(Input.BtType) }}
          </td>
          <td align="end" class="pl-1 pr-2 c_betnum" :class="getNumKeyColor()">
            {{ Input.Num }}
          </td>
          <td align="center" class="pl-1 pr-0 border_class">=</td>
          <td align="end" class="pl-1 pr-2 c_p1">{{ Input.P1 }}</td>
          <td align="end" class="pl-1 pr-0 c_sign" :class="getSignColor(Input.Sign)">
            {{ Input.Sign }}
          </td>
          <td
            align="end"
            class="pl-1 pr-2 border_class"
            :class="getP2KeyColor()"
          >
            {{ Input.P2 }}
          </td>
        </tr>
      </template>
      <template slot="no-data">
        <td></td>
      </template>
    </v-data-table>
    <v-snackbar
      v-model="showprocess.show"
      :bottom="showprocess.y === 'bottom'"
      :color="showprocess.color"
      :left="showprocess.x === 'left'"
      :multi-line="showprocess.mode === 'multi-line'"
      :right="showprocess.x === 'right'"
      :timeout="showprocess.timeout"
      :top="showprocess.y === 'top'"
      :vertical="showprocess.mode === 'vertical'"
    >
      {{ showprocess.text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="yellow"
          text
          v-bind="attrs"
          @click="showprocess.show = false"
        >
          ปิด
        </v-btn>
      </template>
    </v-snackbar>
  </v-col>
</template>

<script>
import globalFunctionMixin from "@/mixins/globalFunctionMixin";
import betFunctionMixin from "@/mixins/betFunctionMixin";

export default {
  name: "PageList_Datatable",
  mixins: [globalFunctionMixin, betFunctionMixin],
  components: {},
  computed: {
    //ดึงข้อมูลจาก vuex มาแสดงในตาราง
    page_data() {
      return this.$store.getters["page/Lines"];
    },
    pages_count() {
      return this.$store.getters["page/Lines"].length;
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        setTimeout(() => {
          console.log(this.$route.query.line_id);
          this.test();
        });
      }
    },
  },
  methods: {
    Init() {
      //โหลด ตาราง
      this.LineID = this.$route.query.line_id;
      this.AgentID = this.$route.query.agent_id;
      this.PageNumber = this.$route.query.page_number;
      this.fetch_table();
      this.table_focus();
      //   console.log(container.scrollTop);
      //   this.$nextTick(() => this.$vuetify.goTo('.abc'))
    },
    table_focus() {
      var container = document.querySelector(
        ".add_table .v-data-table__wrapper"
      );
      var scrollHeight = container.scrollHeight;
      console.log(container.scrollTop);
      container.scrollTop = scrollHeight;
    },
    async fetch_table() {
      let credentials = {
        PeriodDT: sessionStorage.getItem("period"),
        LineID: this.LineID,
        AgentID: this.AgentID,
        PageNumber: this.PageNumber,
        ListType: "page_list",
      };
      //   console.log(credentials);
      await this.$store.dispatch("page/get_list", credentials).then(
        (response) => {
          this.PriceSumLine - 0;
          if (response.line_list.length > 0) {
            this.SetLastLine(response.line_list[response.line_list.length - 1]);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    async OnSendLine(BtType, Num, P1, Sign, P2) {
      let credentials = {
        PeriodDT: sessionStorage.getItem("period"),
        LineID: this.LineID,
        AgentID: this.AgentID,
        CusID: "001",
        PageNumber: this.PageNumber,
        BetTypeID: BtType,
        BetNumber: Num,
        PriceText: P1 + Sign + P2,
        LastUpdate: this.$store.getters["page/Page"]["UpdateDT"],
      };
      //   console.log(credentials.LastUpdate);
      await this.$store.dispatch("page/line_add", credentials).then(
        (response) => {
          this.InputClear();
          //console.log(response.RecentBetLine);
          if (response.IsReload == true) {
            this.fetch_table();
          } else {
            this.SetLastLine(response.RecentBetLine);
          }
          this.ShowSuccess(
            "เพิ่มบรรทัด " +
              this.$store.getters["page/Lines"].length +
              ". " +
              response.RecentBetLine.Number +
              "=" +
              response.RecentBetLine.PriceText +
              " สำเร็จ"
          );
          this.Sending = false;
        },
        (error) => {
          console.log(error);
          switch (error) {
            case "LINE_FULL":
              console.log("บรรทัดเต็ม");
              this.Sending = false;
              break;
            case "PERIOD_NOT_FOUND":
              console.log("ไม่พบงวด");
              this.Sending = false;
              break;
            default:
              this.Sending = false;
              break;
          }
        }
      );
    },
    SetLastLine(p_LastLine) {
      this.Input.BtType = p_LastLine.BetTypeID;

      this.Input.ShowLine = this.$store.getters["page/Lines"].length + 1;

      this.LastLine.ShowLine = this.$store.getters["page/Lines"].length;
      this.LastLine.BtType = p_LastLine.BetTypeID;
      this.LastLine.Num = p_LastLine.Number;

      let PriceText = p_LastLine.PriceText;
      if (PriceText.indexOf(".") > 0) {
        const PriceArray = PriceText.split(".");
        this.LastLine.P1 = PriceArray[0];
        this.LastLine.Sign = "ก";
        this.LastLine.P2 = PriceArray[1];
      } else if (PriceText.indexOf("x") > 0) {
        const PriceArray = PriceText.split("x");
        this.LastLine.P1 = PriceArray[0];
        this.LastLine.Sign = "x";
        this.LastLine.P2 = PriceArray[1];
      } else {
        this.LastLine.P1 = "";
        this.LastLine.Sign = "";
        this.LastLine.P2 = PriceText;
      }
      this.$emit("OnChangeBetType", p_LastLine.BetTypeID);
    },
    OnKeyNumber(Number) {
      switch (this.Stage) {
        case 0:
          if (
            this.Input.Num.length < this.getMaxBetTypeLength(this.Input.BtType)
          )
            this.Input.Num += Number;
          break;
        case 1:
          if (!(Number == "0" && this.Input.P2 == "")) this.Input.P2 += Number;
          break;
      }
      this.table_focus();
    },
    OnKeyEqual() {
      this.table_focus();
      switch (this.Stage) {
        case 0:
          if (
            this.Input.BtType === this.LastLine.BtType &&
            this.Input.Num === this.LastLine.Num
          )
            if (!confirm("ต้องการแทงเลขซ้ำกับบรรทัดบนใช่หรือไม่?")) {
              return;
            }

          if (
            this.Input.Num.length >=
              this.getMinBetTypeLength(this.Input.BtType) &&
            this.Input.Num.length <= this.getMaxBetTypeLength(this.Input.BtType)
          )
            this.Stage = 1;
          break;
        case 1:
          if (this.Sending == true) return;
          this.Sending = true;
          if (this.Input.P1.length == 0 && this.Input.P2.length == 0) {
            if (this.LastLine.Sign == "ก") {
              if (
                !this.canSwap(this.Input.BtType) ||
                this.Input.Num.length != 3
              ) {
                this.Sending = false;
                this.ShowError(
                  "ไม่อนุญาติให้ กลับเลข [" + this.Input.Num + "] ได้"
                );
                return;
              }

              if (this.isTripple(this.Input.Num)) {
                this.ShowError(
                  "ไม่อนุญาติให้ กลับ เลขตอง [" + this.Input.Num + "] ได้"
                );
                this.Sending = false;
                return;
              }
            } else if (this.LastLine.Sign == "x") {
              if (
                !(
                  this.canMultiply(this.Input.BtType) &&
                  this.Input.Num.length > 1
                )
              ) {
                this.Sending = false;
                return;
              }
              if (this.Input.Num.length == 2) {
                if (this.isDouble(this.Input.Num)) {
                  this.ShowError(
                    "ไม่อนุญาติให้ x เลขเบิ้ล [" + this.Input.Num + "] ได้"
                  );
                  this.Sending = false;
                  return;
                }
              } else if (this.Input.Num.length == 3) {
                if (this.isTripple(this.Input.Num)) {
                  this.ShowError(
                    "ไม่อนุญาติให้ x เลขตอง [" + this.Input.Num + "] ได้"
                  );
                  this.Sending = false;
                  return;
                }
              }
            }

            this.Input.P1 = this.LastLine.P1;
            this.Input.Sign = this.LastLine.Sign;
            this.Input.P2 = this.LastLine.P2;
            this.Sending = false;
            return;
          }

          if (this.Input.P2.length == 0 || this.Input.P2 == "0") {
            this.ShowError("ราคาเป็นค่าว่าง หรือ 0 ไม่ได้");
            this.Sending = false;
            return;
          }

          this.OnSendLine(
            this.Input.BtType,
            this.Input.Num,
            this.Input.P1,
            this.Input.Sign,
            this.Input.P2
          );
          break;
      }
    },
    ChangeInputBetType(BtType) {
      if (BtType == "" || BtType == "0") return;
      this.Input.BtType = BtType;
      this.Input.Num = "";
      this.Input.P1 = "";
      this.Input.Sign = "";
      this.Input.P2 = "";
      this.Stage = 0;
    },
    InputClear() {
      this.Input.Num = "";
      this.Input.P1 = "";
      this.Input.Sign = "";
      this.Input.P2 = "";
      this.Stage = 0;
      this.table_focus();
    },
    OnKeyDel() {
      switch (this.Stage) {
        case 0:
          if (this.Input.Num.length > 0)
            this.Input.Num = this.Input.Num.slice(0, -1);
          break;
        case 1:
          if (this.Input.P2.length > 0) {
            this.Input.P2 = this.Input.P2.slice(0, -1);
          } else if (this.Input.Sign.length > 0) {
            this.Input.Sign = "";
            this.Input.P2 = this.Input.P1;
            this.Input.P1 = "";
          } else {
            this.Stage = 0;
          }
          break;
      }
      this.table_focus();
    },
    OnKeySign(Sign) {
      console.log(Sign);
      switch (Sign) {
        case "x":
          if (this.Input.P1 == "" && this.Input.P2 != "") {
            if (this.canMultiply(this.Input.BtType)) {
              // บน บนหน้า หมุน
              if (this.Input.Num.length == 3) {
                if (this.isTripple(this.Input.Num)) {
                  this.ShowError(
                    "ไม่อนุญาติให้ x เลขตอง [" + this.Input.Num + "] ได้."
                  );
                } else {
                  this.Input.Sign = "x";
                  this.Input.P1 = this.Input.P2;
                  this.Input.P2 = "";
                }
              } else if (this.Input.Num.length == 2) {
                if (this.isDouble(this.Input.Num)) {
                  this.ShowError(
                    "ไม่อนุญาติให้ x เลขเบิ้ล [" + this.Input.Num + "] ได้."
                  );
                } else {
                  this.Input.Sign = "x";
                  this.Input.P1 = this.Input.P2;
                  this.Input.P2 = "";
                }
              } else {
                this.ShowError("ต้องเป็นเลข 2 หรือ 3 ตัว ถึงจะคูณได้.");
              }
            } else {
              this.ShowError(
                "ต้องเป็น 3บน 2บน 2ล่าง บน+ล่าง คู่หน้า หรือคู่ถ่าง ถึงจะคูณได้."
              );
            }
          }
          break;
        case ".":
          if (this.Input.P1 == "" && this.Input.P2 != "") {
            if (this.canSwap(this.Input.BtType)) {
              // บน บนหน้า หมุน
              if (this.isTripple(this.Input.Num)) {
                this.ShowError(
                  "ไม่อนุญาติให้ กลับ เลขตอง [" + this.Input.Num + "] ได้."
                );
              } else if (this.Input.Num.length == 3) {
                this.Input.Sign = "ก";
                this.Input.P1 = this.Input.P2;
                this.Input.P2 = "";
              } else {
                this.ShowError("ต้องเป็นเลข 3 ตัว ถึงจะกลับได้.");
              }
            } else {
              this.ShowError("ต้องเป็น 3บน 3บนหน้า หรือหมุน ถึงจะกลับได้.");
            }
          }
          break;
      }
      this.table_focus();
    },
    getNumKeyColor() {
      if (this.Stage == 0) return "lime lighten-4";
      return "";
    },
    getP2KeyColor() {
      if (this.Stage == 1) return "lime lighten-4";
      return "";
    },
    getBetTypeColor(betType) {
      let BT = 0;
      switch (betType) {
        case "1": //บน
        case "4": //พวง
        case "6": //บน/น
        case "7": //บน/ก
        case "8": //บน/ท
        case "11": //บน/น
        case "12": //บน/ถ
        case "13": //ล.บ.
        case "15": //19/บ
        case "17": //ค.ต.
          BT = 0;
          break;
        case "2": //ล่าง
        case "5": //หมุน
        case "9": //ล่าง/น
        case "10": //ล่าง/ท
        case "14": //ล.ล
        case "16": //19/ล
          BT = 1;
          break;
        case "3":
          BT = 2;
          break;
        case "18": //3น.
        case "19": //3น.
        case "20": //3น/น
        case "21": //3น/น
        case "22": //3น/ท
        case "23": //3น/น
        case "24": //3น/ถ
        case "25": //3น.
        case "26": //ต/3น.
          BT = 3;
          break;
      }
      let text_color = [
        "indigo--text",
        "red--text",
        "teal--text",
        "deep-orange--text",
      ];
      return text_color[BT];
    },
    getDirectPrice(PriceText) {
      if (PriceText.indexOf(".") > -1)
        return PriceText.substr(0, PriceText.indexOf("."));
      if (PriceText.indexOf("x") > -1)
        return PriceText.substr(0, PriceText.indexOf("x"));
      return "";
    },
    getSign(PriceText) {
      if (PriceText.indexOf(".") > -1) return "ก";
      if (PriceText.indexOf("x") > -1) return "x";
      return "";
    },
    getSignColor(Sign) {
      if (Sign == "ก") return "red--text";
      return "";
    },
    getSwapPrice(PriceText) {
      if (PriceText.indexOf(".") > -1)
        return PriceText.substr(
          PriceText.indexOf(".") + 1,
          PriceText.length - PriceText.indexOf(".")
        );
      if (PriceText.indexOf("x") > -1)
        return PriceText.substr(
          PriceText.indexOf("x") + 1,
          PriceText.length - PriceText.indexOf("x")
        );
      return PriceText;
    },
    getPriceSumLine(PriceSum) {
      console.log("PriceSum");
      console.log(PriceSum);
      this.PriceSumLine += parseInt(PriceSum);
      return this.PriceSumLine;
    },
    getPriceColor(Price) {
      if (parseInt(Price) >= 1000) return "red--text";
      return "";
    },
    getStatusText(status) {
      const statusText = [
        "ปกติ", //0
        "ลบ",
        "แก้ไข",
        "อั้น",
        "ตีกลับ",
        "ถูก",
        "เลขเต็ม",
      ];
      return statusText[parseInt(status)];
    },
    getStatusColor(status) {
      const statusColor = [
        "red--text",
        "grey--text",
        "green--text",
        "red--text",
        "deep-purple--text",
        "black--text",
        "black--text",
        "black--text",
        "black--text",
        "black--text",
        "red--text",
      ];
      return statusColor[parseInt(status) + 1] + " pl-10";
    },
    ShowError(ErrorText) {
      this.showprocess.text = ErrorText;
      this.showprocess.color = "red darken-4";
      this.showprocess.show = true;
    },
    ShowSuccess(Text) {
      this.showprocess.text = Text;
      this.showprocess.color = "green darken-1";
      this.showprocess.show = true;
    },
    row_click: function(item) {
      this.selectedId = item.LineNumber;
      this.$set(item, "selected", true);
      //   console.log(item.id);
    },
    popup_menu: function(e, item) {
      e.preventDefault();
      this.$refs.PopupMenu1.Hide(e.clientX, e.clientY);
      this.$nextTick(() => {
        this.$refs.PopupMenu1.SetAgentID(this.AgentID);
        this.$refs.PopupMenu1.SetLineID(this.LineID);
        this.$refs.PopupMenu1.Show(item.PageNumber);
        this.selectedId = item.LineNumber;
        //console.log(item);
      });
    },
  },
  data: () => ({
    Sending: false,

    LineID: "",
    AgentID: "",
    PageNumber: "",
    selectedId: -1,
    PriceSumLine: 0,

    Input: {
      ShowLine: 1,
      LineNumber: 1,
      BtType: "1",
      Num: "",
      P1: "",
      P2: "",
      Sign: "",
    },

    LastLine: {
      ShowLine: 1,
      BtType: "1",
      Num: "",
      P1: "",
      P2: "",
      Sign: "",
    },

    Stage: 0,

    showprocess: {
      show: false,
      text: "",
      timeout: 1000,
      color: "red",
      mode: "",
      x: null,
      y: "top",
    },

    id_column: "id_column_class",
    id_column_new: "id_column_new_class",
    border_class: "border_class",
  }),
};
</script>

<style scoped>
::v-deep .id_column_class {
  background-color: rgb(29, 95, 81) !important;
  color: whitesmoke;
  width: 10% !important;
  font-size: 0.8em !important;
}

::v-deep .id_column_new_class {
  background-color: rgb(166, 226, 169) !important;
  color: black;
  width: 10% !important;
  font-size: 0.8em !important;
}

::v-deep table {
  background-color: #eceff1;
}

::v-deep tr:nth-child(odd) {
  background-color: #edfafc;
}

::v-deep table tbody tr td {
  font-size: 0.9em !important;
  color: rgb(88, 88, 88);
  font-weight: bold;
}

::v-deep .border_class {
  border-right: 1px solid rgb(190, 197, 255);
}

.c_bettype{
    width: 15%;
}
.c_betnum{
    width: 20%;
}
.c_p1{
    width: 20%;
}
.c_sign{
    width: 2%;
}
</style>
