<template>
  <v-dialog
    v-model="ShowDialog"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    persistent
    dense
    transition="dialog-bottom-transition"
    max-width="400px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn rounded color="warning" dark v-bind="attrs" v-on="on">
        <v-icon left>
          mdi-plus-outline
        </v-icon>
        เพิ่ม
      </v-btn>
    </template>
    <v-card class="flexcard" height="100%" color="grey lighten-3">
      <v-toolbar dark color="primary" dense>
        <v-toolbar-title>ปิดรับ .... เวลา ....</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="ShowDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-title class="pa-1">
        <Datatable ref="KeyDatatable" @OnChangeBetType="OnChangeBetType" />
      </v-card-title>
      <v-card-text class="keyboard pa-1">
        <div><v-btn class="btn" @click="ClickChangeBetType('3')" dark>อื่นๆ</v-btn></div>
        <div><v-btn class="btn" :class="getTopColor()" @click="ClickChangeBetType('1')" dark>บน</v-btn></div>
        <div><v-btn class="btn" :class="getDownColor()" @click="ClickChangeBetType('2')" dark>ล่าง</v-btn></div>
        <div class="del">
          <v-btn
            class="btn"
            color="indigo darken-3"
            @touchstart="OnTouchDel()"
            @click.native="OnClickDel()"
            dark
          >
            <v-icon large>
              mdi-arrow-left-bold
            </v-icon></v-btn
          >
        </div>
        <div>
          <v-btn
            class="btn_number"
            @touchstart="OnTouchNum(7)"
            @click.native="OnClickNum(7)"
            dark
            >7</v-btn
          >
        </div>
        <div>
          <v-btn
            class="btn_number"
            @touchstart="OnTouchNum(8)"
            @click.native="OnClickNum(8)"
            dark
            >8</v-btn
          >
        </div>
        <div>
          <v-btn
            class="btn_number"
            @touchstart="OnTouchNum(9)"
            @click.native="OnClickNum(9)"
            dark
            >9</v-btn
          >
        </div>
        <div class="multiply">
          <v-btn
            class="btn_2"
            color="amber darken-2"
            @touchstart="OnTouchSign('x')"
            @click.native="OnClickSign('x')"
            dark
          >
            <v-icon>
              mdi-close
            </v-icon></v-btn
          >
        </div>
        <div>
          <v-btn
            class="btn_number"
            @touchstart="OnTouchNum(4)"
            @click.native="OnClickNum(4)"
            dark
            >4</v-btn
          >
        </div>
        <div>
          <v-btn
            class="btn_number"
            @touchstart="OnTouchNum(5)"
            @click.native="OnClickNum(5)"
            dark
            >5</v-btn
          >
        </div>
        <div>
          <v-btn
            class="btn_number"
            @touchstart="OnTouchNum(6)"
            @click.native="OnClickNum(6)"
            dark
            >6</v-btn
          >
        </div>
        <div>
          <v-btn
            class="btn_number"
            @touchstart="OnTouchNum(1)"
            @click.native="OnClickNum(1)"
            dark
            >1</v-btn
          >
        </div>
        <div>
          <v-btn
            class="btn_number"
            @touchstart="OnTouchNum(2)"
            @click.native="OnClickNum(2)"
            dark
            >2</v-btn
          >
        </div>
        <div>
          <v-btn
            class="btn_number"
            @touchstart="OnTouchNum(3)"
            @click.native="OnClickNum(3)"
            dark
            >3</v-btn
          >
        </div>
        <div class="zero">
          <v-btn
            class="btn_number"
            @touchstart="OnTouchNum(0)"
            @click.native="OnClickNum(0)"
            dark
            >0</v-btn
          >
        </div>
        <div>
          <v-btn
            class="btn"
            color="light-blue darken-4"
            @touchstart="OnTouchSign('.')"
            @click.native="OnClickSign('.')"
            dark
            >ก.</v-btn
          >
        </div>
        <div class="equal">
          <v-btn
            class="btn_2"
            color="red darken-1"
            @touchstart="OnTouchEqual('=')"
            @click.native="OnClickEqual('=')"
            dark
            >=</v-btn
          >
        </div>
      </v-card-text>
    </v-card>
        <BetTypeDlg @OnChangeBetType="ChangeInputBetType" ref="BetTypeDlg" />
  </v-dialog>
</template>

<script>
import Datatable from "@/components/Cus/Page/PageList/Add_Data_Table";
import BetTypeDlg from "@/components/Cus/Page/PageList/BetTypeDlg";

export default {
  name: "KEY_PAD",

  components: {
    Datatable,
    BetTypeDlg,
  },

  //mounted: {},
  computed: {
    width() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "90%";
        case "sm":
          return "90%";
        case "md":
          return "60%";
        case "lg":
          return "50%";
        case "xl":
          return "50%";
      }
      return "50%";
    },
  },
  watch: {
    ShowDialog(val) {
      if (val) {
        setTimeout(() => {
          this.Init();
        });
      }
    },
  },
  methods: {
    Init() {
      this.$refs.KeyDatatable.Init();
      //alert("Init");
    },
    OnChangeBetType(BetType) {
      this.BetType = BetType;
    },
    ClickChangeBetType(BetType){
        if(BetType=='1'){
            this.$refs.KeyDatatable.ChangeInputBetType('1');
            this.BetType='1';
        }else if(BetType=='2'){
            this.$refs.KeyDatatable.ChangeInputBetType('2');
            this.BetType='2';
        }else{
            this.$refs.BetTypeDlg.Show(this.BtType);
        }
    },
    ChangeInputBetType(value){
        this.$refs.KeyDatatable.ChangeInputBetType(value);
        this.BetType=value;
    },
    getTopColor() {
      let BetType = this.BetType;
      if(BetType=="1" || BetType=="3" || BetType=="4" || BetType=="6" || BetType=="7" || BetType=="8" || BetType=="11" || BetType=="12" || BetType=="13" || BetType=="15" || BetType=="17" || BetType=="18" || BetType=="19" || BetType=="20" || BetType=="21" || BetType=="22" || BetType=="23" || BetType=="24" || BetType=="25" || BetType=="26")
      return "blue darken-4";
      return "";
    },
    getDownColor() {
      let BetType = this.BetType;
      if(BetType=="2" ||  BetType=="4" || BetType=="5" || BetType=="9" || BetType=="10" || BetType=="14" || BetType=="16" )
      return "red darken-4";
      return "";
    },
    OnTouchNum(Number) {
      this.isTouch = true;
      console.log("Touch " + Number);
      window.navigator.vibrate(40);
      this.$refs.KeyDatatable.OnKeyNumber(Number);
    },
    OnClickNum(Number) {
      if (this.isTouch == true) return;
      console.log("Click " + Number);
      window.navigator.vibrate(40);
      this.$refs.KeyDatatable.OnKeyNumber(Number);
    },
    OnTouchDel() {
      this.isTouch = true;
      window.navigator.vibrate(40);
      this.$refs.KeyDatatable.OnKeyDel();
    },
    OnClickDel() {
      if (this.isTouch == true) return;
      window.navigator.vibrate(40);
      this.$refs.KeyDatatable.OnKeyDel();
    },
    OnTouchSign(Sign) {
      this.isTouch = true;
      window.navigator.vibrate(40);
      this.$refs.KeyDatatable.OnKeySign(Sign);
    },
    OnClickSign(Sign) {
      if (this.isTouch == true) return;
      window.navigator.vibrate(40);
      this.$refs.KeyDatatable.OnKeySign(Sign);
    },
    OnTouchEqual() {
      this.isTouch = true;
      window.navigator.vibrate(40);
      this.$refs.KeyDatatable.OnKeyEqual();
    },
    OnClickEqual() {
      if (this.isTouch == true) return;
      window.navigator.vibrate(40);
      this.$refs.KeyDatatable.OnKeyEqual();
    },
  },
  data: () => ({
    ShowDialog: false,
    isTouch: false,
    BetType: 0, //0 = บน 1 = ล่าง 2 = อื่นๆ
    balance_items: [
      { text: "ทั้งหมด", value: 0 },
      { text: "ที่มียอดค้างเก่า", value: 1 },
    ],
    waitingpage_items: [
      { text: "ทั้งหมด", value: 0 },
      { text: "โพยรอส่ง", value: 1 },
    ],
    status_items: [
      { text: "ทั้งหมด", value: 0 },
      { text: "ที่มียอดค้างเก่า", value: 1 },
    ],
  }),
};
</script>

<style scoped>
::v-deep .v-dialog:not(.v-dialog--fullscreen) {
  top: 0 !important;
  right: 0 !important;
  position: absolute !important;
  margin-top: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
  background-color: white;
  height: 100vh;
}

::v-deep .v-dialog:is(.v-dialog--fullscreen) {
  top: 0 !important;
  right: 0 !important;
  position: absolute !important;
  margin-top: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
  background-color: white;
}

.keyboard {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: minmax(50px, auto);
  width: 100%;
  height: 50%;
  font-weight: bold;
}

.zero {
  grid-column: 1/3;
}

.multiply {
  grid-row-start: 2;
  grid-row-end: 4;
  grid-column-start: 4;
  grid-column-end: 5;
}

.equal {
  grid-row-start: 4;
  grid-row-end: 6;
  grid-column-start: 4;
  grid-column-end: 5;
}
/* .keyboard .v-btn {
  background-color: darkslateblue !important;
  height: 96% !important;
  width: 99% !important;
} */

/* .keyboard > div {
  text-align: center;
  font-size: 30px;
} */

.btn {
  height: 96% !important;
  width: 99% !important;
}

.btn_2 {
  height: 98% !important;
  width: 99% !important;
}

::v-deep .btn_number {
  background-color: darkcyan !important;
  height: 96% !important;
  width: 99% !important;
}
</style>
