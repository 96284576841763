<template>
  <div>
    <v-app-bar dense fixed app color="purple darken-4" dark>
      <v-btn to="/menu_cus" icon>
        <v-icon>mdi-home</v-icon>
      </v-btn>
      <v-toolbar-title>งวด {{ Period }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon>
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </v-app-bar>
    <v-breadcrumbs :items="bc_items" class="grey lighten-3 pa-2" dense>
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <PageListTable />
  </div>
</template>

<script>
import globalFunctionMixin from "@/mixins/globalFunctionMixin";
import PageListTable from "@/components/Cus/Page/PageList/Datatable";

//   { text: "บัญชีรวม", id: 3 },
//   { text: "อัตราจ่าย", id: 4 },
//   { text: "บันทึก", id: 5 },
//   { text: "ใบปะหน้า", id: 6 },
//   { text: "ผู้ดูแล", id: 7 },

export default {
  name: "Line_Data",
  mixins: [globalFunctionMixin],
  components: {
    PageListTable,
  },

  //get line name
  mounted() {
    this.ckLogin();

    this.bc_items[0].text = sessionStorage.getItem("host_id");
    this.bc_items[0].to = { path: "line_data" };

    this.bc_items[1].text = this.$route.query.line_id;
    this.bc_items[1].to = {
      path: "agent_data",
      query: {
        line_id: this.$route.query.line_id,
      },
    };

    this.bc_items[3].text = this.$route.query.page_number;

    //     const resolved = this.$router.resolve({
    //   name: 'agent_data',
    //   params: { line_id: this.$route.query.line_id }
    // })
    // this.bc_items[1].to=resolved;
    // this.bc_items[1].to = "/agent_data?line_id=" + this.$route.query.line_id;
  },
  data: () => ({
    component: "PageListTable",
    Period: "",
    bc_items: [
      {
        text: "a1 ถิง",
        disabled: false,
      },
      {
        text: "Link 2",
        disabled: false,
      },
      {
        text: "Link 3",
        disabled: false,
      },
      {
        text: "Link 4",
        disabled: false,
      },
    ],
  }),
  created() {
    //โหลด ตาราง
    this.LineID = this.$route.query.line_id;
    this.AgentID = this.$route.query.agent_id;
    this.PageNumber = this.$route.query.page_number;
    this.fetch_table();
    this.Period = this.getAbbrPeriodThai(sessionStorage.getItem("period"));
  },
  methods: {
    async fetch_table() {
      let credentials = {
        PeriodDT: sessionStorage.getItem("period"),
        LineID: this.LineID,
        AgentID: this.AgentID,
        ListType: "get_detail",
      };
      await this.$store.dispatch("agent/get_detail", credentials).then(
        (response) => {
          console.log(response);
          //   console.log(this.$store.getters["agent/AgentDetail"][0].Nickname);
          this.bc_items[2].text =
            this.$route.query.agent_id + " ( " + response[0].Nickname + " )";
          this.bc_items[2].to = {
            path: "pages_list",
            query: {
              line_id: this.$route.query.line_id,
              agent_id: this.$route.query.agent_id,
            },
          };
          //   this.$store.getters["pages/Pages"];
        },
        (error) => {
          console.log(error);
        }
      );
    },
  },
};
</script>

<style scoped>
/* ::v-deep .v-icon {
  font-size: 1.6em !important;
} */
</style>
