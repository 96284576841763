import { render, staticRenderFns } from "./Page.vue?vue&type=template&id=5988466c&scoped=true&"
import script from "./Page.vue?vue&type=script&lang=js&"
export * from "./Page.vue?vue&type=script&lang=js&"
import style0 from "./Page.vue?vue&type=style&index=0&id=5988466c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5988466c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VAppBar,VBreadcrumbs,VBtn,VIcon,VSpacer,VToolbarTitle})
