<template>
  <div
    style="-moz-user-select: none; -webkit-user-select: none; -ms-user-select:none; user-select:none;-o-user-select:none;"
    unselectable="on"
    onselectstart="return false;"
    onmousedown="return false;"
    id="printSection"
  >
    <!-- :headers="headers" -->
    <v-data-table
      :items="lines_list"
      :items-per-page="10"
      item-key="ShowLine"
      single-select
      class="elevation-1"
      hide-default-footer
      disable-pagination
      mobile-breakpoint="0"
      height="60vh"
      fixed-header
      dense
    >
      <!-- :class="{'h4': $vuetify.breakpoint. mdAndUp}" -->
      <template v-slot:top>
        <v-toolbar flat color="grey lighten-5">
          <AddData />
          <v-btn rounded color="info ml-2" dark @click="printSection">
            <v-icon left>
              mdi-printer
            </v-icon>
            พิมพ์
          </v-btn>
          <v-divider class="mx-4 info" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn rounded outlined color="cyan">
            <v-icon left>
              mdi-filter-variant
            </v-icon>
            จัดการ
          </v-btn>
        </v-toolbar>
        <div class="d-flex justify-end pt-1 pb-1">
          <v-sheet
            color="light-blue lighten-5"
            elevation="1"
            width="150"
            class="text-center d-flex flex-column align-center justify-center"
            >สร้าง [{{ page_data.CreateBy }}]</v-sheet
          >
          <v-sheet
            color="brown lighten-5"
            elevation="1"
            width="150"
            class="text-center d-flex flex-column align-center justify-center"
            >{{ getDTShort(page_data.CreateDT) }}</v-sheet
          >
          <v-sheet
            color="teal lighten-5"
            elevation="1"
            width="150"
            class="text-center d-flex flex-column align-center justify-center"
            >ส่ง [{{ page_data.SendBy }}]</v-sheet
          >
          <v-sheet
            color="brown lighten-5"
            elevation="1"
            width="150"
            class="text-center d-flex flex-column align-center justify-center"
            >{{ getDTShort(page_data.SendDT) }}</v-sheet
          >
          <v-sheet
            :color="getPageStatusColor()"
            elevation="1"
            width="100"
            class="text-center d-flex flex-column align-center justify-center white--text"
            >{{getPageStatusText()}}</v-sheet
          >
        </div>
        <!-- <v-alert dense text class="text-center" type="success">
          แสดง <strong>ทั้งหมด</strong> รวม
          <strong>{{ line_count }}</strong> รายการ
        </v-alert> -->
      </template>

      <template v-slot:item="{ item }">
        <tr
          @contextmenu="popup_menu($event, item)"
          @click="row_click(item)"
          :class="{ 'purple lighten-4': item.ShowLine === selectedId }"
        >
          <!-- <td :class="id_column">{{ item.id }}</td> -->
          <td align="end" :class="id_column">{{ item.ShowLine }}.</td>
          <td
            align="center"
            class=" pl-1 pr-2"
            :class="[border_class, getBetTypeColor(item.BetTypeID)]"
          >
            {{ getBetTypeName(item.BetTypeID) }}
          </td>
          <td
            align="end"
            class="pl-1 pr-2"
            :class="getBetNumberColor(item.Status)"
            width="5%"
          >
            {{ item.Number }}
          </td>
          <td align="end" class="pl-1 pr-2 indigo--text" :class="border_class">
            &nbsp;=
          </td>
          <td
            align="end"
            class="c_P1 pl-1 pr-2"
            :class="getPriceColor(getDirectPrice(item.PriceText))"
          >
            {{ add_comma(getDirectPrice(item.PriceText)) }}
          </td>
          <td
            align="end"
            class="c_Sign pl-1 pr-0"
            :class="getSignColor(getSign(item.PriceText))"
          >
            {{ getSign(item.PriceText) }}
          </td>
          <td
            align="end"
            class="c_P2 pl-1 pr-2"
            :class="[border_class, getPriceColor(getSwapPrice(item.PriceText))]"
          >
            {{ add_comma(getSwapPrice(item.PriceText)) }}
          </td>
          <td
            align="center"
            class="font-weight-bold"
            :class="[border_class, getBetLineStatusColor(item.Status)]"
          >
            {{ getStatusText(item.Status) }}
          </td>
          <td align="end" class="font-weight-bold border_class">
            {{ add_comma(parseInt(item.PriceSum) + parseInt(item.PriceLoy)) }}
          </td>
          <td align="end" class="font-weight-bold border_class">
            {{ add_comma(item.PriceSumLine) }}
          </td>
          <td align="end" class="font-weight-bold" :class="border_class">
            {{ add_comma(item.GetPrize) }}
          </td>
          <td align="center" class="" :class="border_class">
            {{ getDT(item.UpdateDT) }}
          </td>
          <td align="center" class="font-weight-bold" :class="border_class">
            {{ item.EditBy }}
          </td>
          <td align="start" class="">
            {{ getDT(item.EditDT) }}
          </td>
        </tr>
      </template>

      <template slot="header">
        <thead>
          <tr>
            <th colspan="1" class="h_id">
              #
            </th>
            <th
              class="h_num text-center grey lighten-2 white-space: normal"
              colspan="3"
              width="20%"
            >
              เลข
            </th>
            <th
              class="h_betprice text-center grey lighten-4 white-space: normal"
              colspan="3"
            >
              เงินแทง
            </th>
            <th
              class="h_status text-center grey lighten-2"
              colspan="1"
              width="50%"
            >
              สถานะ
            </th>
            <th class="h_betsum text-end grey lighten-4" colspan="1">
              ยอดแทง
            </th>
            <th class="h_pricesum text-end grey lighten-2" :colspan="1">
              รวมเงิน
            </th>
            <th class="h_winsum text-end grey lighten-4" :colspan="1">
              ยอดถูก
            </th>
            <th class="h_bettime text-center grey lighten-2" :colspan="1">
              เวลาพิมพ์
            </th>
            <th class="h_editby text-center grey lighten-4" :colspan="1">
              แก้ไขโดย
            </th>
            <th class="h_edittime text-center grey lighten-2" :colspan="1">
              เวลาแก้ไข
            </th>
          </tr>
        </thead>
      </template>

      <template slot="body.append">
        <!-- v-if="!isTouchScreendevice()" -->
        <tr>
          <td align="end" class="id_column_sum_class">{{ Input.ShowLine }}.</td>
          <td
            align="center"
            class="pl-1 pr-2"
            :class="[border_class, getBetTypeColor(Input.BtType)]"
          >
            {{ getBetTypeName(Input.BtType) }}
          </td>
          <td class=" pl-0 pr-0">
            <v-text-field
              class="btNum"
              ref="txtNum"
              v-model="Input.Num"
              @keypress="OnNumKey($event)"
              @keyup.enter="OnNumEnter"
              @keydown="OnNumKeyDown($event)"
              type="number"
              solo
              flat
              hide-details
              dense
            ></v-text-field>
          </td>
          <td class="pl-1 pr-2 indigo--text border_class" align="end">
            &nbsp;=
          </td>
          <td class="pl-1 pr-2" align="end">
            {{ Input.P1 }}
          </td>

          <td class="pl-1 pr-0 red--text" align="end">
            {{ Input.Sign }}
          </td>
          <td class="pl-1 pr-0">
            <v-text-field
              class="btP2"
              ref="txtP2"
              v-model="Input.P2"
              @keypress="OnP2Key($event)"
              @keyup.enter="OnPriceEnter"
              @keydown.delete="OnPriceDel($event)"
              @keydown="OnP2KeyDown($event)"
              @focus="onP2Focus()"
              type="number"
              solo
              flat
              hide-details
              dense
            ></v-text-field>
          </td>
          <td class="text-center pl-2 pr-2">
            <v-btn color="blue lighten-5" @click="InputOK()" block>ตกลง</v-btn>
          </td>
          <td class="text-center pl-2 pr-2">
            <v-btn color="pink lighten-5" @click="InputClear()" block
              >ยกเลิก</v-btn
            >
          </td>
        </tr>
      </template>
    </v-data-table>

    <div class="d-flex justify-start pt-2">
      <v-btn class="mx-2" fab dark small color="primary">
        <v-icon dark>
          mdi-arrow-up-thick
        </v-icon>
      </v-btn>
      <v-sheet
        color="light-blue lighten-5"
        elevation="1"
        width="100"
        class="text-center d-flex flex-column align-center justify-center"
        >ยอดรวม</v-sheet
      >
      <v-sheet
        color="brown lighten-5"
        elevation="1"
        width="120"
        class="text-center font-weight-bold d-flex flex-column align-center justify-center"
        >{{ add_comma(price_sum) }}</v-sheet
      >
      <v-sheet
        color="teal lighten-5"
        elevation="1"
        width="100"
        class="text-center d-flex flex-column align-center justify-center"
        >ยอดลอย</v-sheet
      >
      <v-sheet
        color="brown lighten-5"
        elevation="1"
        width="120"
        class="text-center font-weight-bold d-flex flex-column align-center justify-center"
        >{{ add_comma(price_sum_loy) }}</v-sheet
      >
    </div>
    <BetTypeDlg @OnChangeBetType="ChangeInputBetType" ref="BetTypeDlg" />
    <PopupMenu ref="PopupMenu1" @del="OnDelLine" @send_back="OnSendBackLine" @resend="OnResendLine" />
  </div>
</template>

<script>
import globalFunctionMixin from "@/mixins/globalFunctionMixin";
import betFunctionMixin from "@/mixins/betFunctionMixin";
import PopupMenu from "@/components/Cus/Page/PageList/Popup_Menu";
import AddData from "@/components/Cus/Page/PageList/Add_Data";
import BetTypeDlg from "@/components/Cus/Page/PageList/BetTypeDlg";

export default {
  name: "PageList_Datatable",
  mixins: [globalFunctionMixin, betFunctionMixin],
  components: {
    PopupMenu,
    AddData,
    BetTypeDlg,
  },
  created() {
    //โหลด ตาราง
    this.LineID = this.$route.query.line_id;
    this.AgentID = this.$route.query.agent_id;
    this.PageNumber = this.$route.query.page_number;
    this.fetch_table();
  },
  computed: {
    //ดึงข้อมูลจาก vuex มาแสดงในตาราง
    lines_list() {
      return this.$store.getters["page/Lines"];
    },
    line_count() {
      return this.$store.getters["page/Lines"].length;
    },
    price_sum() {
      if (this.$store.getters["page/Page"]["PriceSum"] == null) return 0;
      return this.$store.getters["page/Page"]["PriceSum"];
    },
    price_sum_loy() {
      if (this.$store.getters["page/Page"]["PriceSumLoy"] == null) return 0;
      return this.$store.getters["page/Page"]["PriceSumLoy"];
    },
    page_data() {
      return this.$store.getters["page/Page"];
    },
  },
  methods: {
    async fetch_table() {
      let credentials = {
        PeriodDT: sessionStorage.getItem("period"),
        LineID: this.LineID,
        AgentID: this.AgentID,
        PageNumber: this.PageNumber,
        ListType: "page_list",
      };
      //   console.log(credentials);
      await this.$store.dispatch("page/get_list", credentials).then(
        (response) => {
          this.PriceSumLine = 0;
          if (response.line_list.length > 0) {
            this.SetLastLine(response.line_list[response.line_list.length - 1]);
          } else {
            this.Input.ShowLine = 1;
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    SetLastLine(p_LastLine) {
      this.Input.BtType = p_LastLine.BetTypeID;

      this.Input.ShowLine = this.$store.getters["page/Lines"].length + 1;

      this.LastLine.ShowLine = this.$store.getters["page/Lines"].length;
      this.LastLine.BtType = p_LastLine.BetTypeID;
      this.LastLine.Num = p_LastLine.Number;

      let PriceText = p_LastLine.PriceText;
      if (PriceText.indexOf(".") > 0) {
        const PriceArray = PriceText.split(".");
        this.LastLine.P1 = PriceArray[0];
        this.LastLine.Sign = "ก";
        this.LastLine.P2 = PriceArray[1];
      } else if (PriceText.indexOf("x") > 0) {
        const PriceArray = PriceText.split("x");
        this.LastLine.P1 = PriceArray[0];
        this.LastLine.Sign = "x";
        this.LastLine.P2 = PriceArray[1];
      } else {
        this.LastLine.P1 = "";
        this.LastLine.Sign = "";
        this.LastLine.P2 = PriceText;
      }

      //console.log(this.LastLine);
      // this.LastLine.P2 = response.page_list[response.page_list.length - 1].BetTypeID;
      // this.LastLine.Sign = response.page_list[response.page_list.length - 1].BetTypeID;
      //   console.log(response.page_list[response.page_list.length-1].BetTypeID);
    },
    //KEY
    InputClear() {
      this.Input.Num = "";
      this.Input.P1 = "";
      this.Input.Sign = "";
      this.Input.P2 = "";
      this.$refs.txtNum.focus();
    },
    InputOK() {
      this.$refs.BetTypeDlg.Show("1");
    },
    OnNumKey(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      //console.log(charCode);
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        //not numaric
        if (charCode == 32) {
          //console.log("Change BTType");
          this.$refs.BetTypeDlg.Show(this.Input.BtType);
        }
        if (evt.key == "/") this.ChangeInputBetType("1");
        if (evt.key == "*") this.ChangeInputBetType("2");
        if (evt.key == "-") this.ChangeInputBetType("3");
        evt.preventDefault();
      } else {
        if (
          this.Input.Num.length >= this.getMaxBetTypeLength(this.Input.BtType)
        )
          evt.preventDefault();
        return true;
      }
    },
    ChangeInputBetType(BtType) {
      if (BtType == "" || BtType == "0") return;
      this.Input.BtType = BtType;
      this.Input.Num = "";
      this.Input.P1 = "";
      this.Input.Sign = "";
      this.Input.P2 = "";
      this.$refs.txtNum.focus();
    },
    OnNumEnter() {
      this.$refs.txtP2.focus();
      //   console.log("ENTER");
    },
    onP2Focus() {
      if (this.Input.Num.length < this.getMinBetTypeLength(this.Input.BtType)) {
        this.$refs.txtNum.focus();
      } else if (
        this.Input.Num.length > this.getMaxBetTypeLength(this.Input.BtType)
      ) {
        this.Input.Num == "";
        this.$refs.txtNum.focus();
      }
    },
    OnP2KeyDown(evt) {
      if (evt.key == "ArrowDown" || evt.key == "ArrowUp") evt.preventDefault();
    },
    OnNumKeyDown(evt) {
      if (evt.key == "ArrowDown" || evt.key == "ArrowUp") evt.preventDefault();
    },
    OnPriceEnter() {
      if (this.Sending == true) return;
      this.Sending = true;
      // empty price text
      if (this.Input.P1.length == 0 && this.Input.P2.length == 0) {
        if (this.LastLine.Sign == "ก") {
          if (!this.canSwap(this.Input.BtType) || this.Input.Num.length != 3) {
            this.Sending = false;
            return;
          }

          if (this.isTripple(this.Input.Num)) {
            this.ShowError(
              "ไม่อนุญาติให้ กลับ เลขตอง [" + this.Input.Num + "] ได้"
            );
            this.Sending = false;
            return;
          }
        } else if (this.LastLine.Sign == "x") {
          if (
            !(this.canMultiply(this.Input.BtType) && this.Input.Num.length > 1)
          ) {
            this.Sending = false;
            return;
          }
          if (this.Input.Num.length == 2) {
            if (this.isDouble(this.Input.Num)) {
              this.ShowError(
                "ไม่อนุญาติให้ x เลขเบิ้ล [" + this.Input.Num + "] ได้"
              );
              this.Sending = false;
              return;
            }
          } else if (this.Input.Num.length == 3) {
            if (this.isTripple(this.Input.Num)) {
              this.ShowError(
                "ไม่อนุญาติให้ x เลขตอง [" + this.Input.Num + "] ได้"
              );
              this.Sending = false;
              return;
            }
          }
        }
        this.Input.P1 = this.LastLine.P1;
        this.Input.Sign = this.LastLine.Sign;
        this.Input.P2 = this.LastLine.P2;
        this.Sending = false;
        return;
      }

      if (this.Input.P2.length == 0 || this.Input.P2 == "0") {
        this.ShowError("ราคาเป็นค่าว่าง หรือ 0 ไม่ได้");
        this.Sending = false;
        return;
      }

      const NumMin = this.getMinBetTypeLength(this.Input.BtType);
      const NumMax = this.getMaxBetTypeLength(this.Input.BtType);
      const NumLen = this.Input.Num.length;
      // Number incorrect
      if (NumLen < NumMin || NumLen > NumMax) {
        this.Sending = false;
        this.$refs.txtNum.focus();
        return;
      }
      this.OnSendLine(
        this.Input.BtType,
        this.Input.Num,
        this.Input.P1,
        this.Input.Sign,
        this.Input.P2
      );
      //this.Sending = false;
    },
    async OnSendLine(BtType, Num, P1, Sign, P2) {
      let credentials = {
        PeriodDT: sessionStorage.getItem("period"),
        LineID: this.LineID,
        AgentID: this.AgentID,
        CusID: "001",
        PageNumber: this.PageNumber,
        BetTypeID: BtType,
        BetNumber: Num,
        PriceText: P1 + Sign + P2,
        LastUpdate: this.$store.getters["page/Page"]["UpdateDT"],
      };
      //   console.log(credentials.LastUpdate);
      await this.$store.dispatch("page/line_add", credentials).then(
        (response) => {
          this.InputClear();
          //console.log(response.RecentBetLine);
          if (response.IsReload == true) {
            console.log("RELOAD");
            this.fetch_table();
          } else {
            this.SetLastLine(response.RecentBetLine);
          }
          this.Sending = false;
        },
        (error) => {
          console.log(error);
          switch (error) {
            case "LINE_FULL":
              console.log("บรรทัดเต็ม");
              this.Sending = false;
              break;
            case "PERIOD_NOT_FOUND":
              console.log("ไม่พบงวด");
              this.Sending = false;
              break;
            default:
              this.Sending = false;
              break;
          }
        }
      );
    },
    async OnDelLine(LineNumber) {
      let credentials = {
        PeriodDT: sessionStorage.getItem("period"),
        LineID: this.LineID,
        AgentID: this.AgentID,
        CusID: "001",
        PageNumber: this.PageNumber,
        LineNumber: LineNumber,
        LastUpdate: this.$store.getters["page/Page"]["UpdateDT"],
      };
      await this.$store.dispatch("page/line_del", credentials).then(
        (response) => {
          this.fetch_table();
          //   console.log(response);
        },
        (error) => {
          console.log(error);
          switch (error) {
            case "BET_LINE_NOT_FOUND":
              this.ShowError("ไม่พบบรรทัดที่ต้องการลบ");
              this.fetch_table();
              break;
            //     default:
            //       this.Sending = false;
            //       break;
          }
        }
      );
    },
    async OnSendBackLine(LineNumber) {
      let credentials = {
        PeriodDT: sessionStorage.getItem("period"),
        LineID: this.LineID,
        AgentID: this.AgentID,
        CusID: "001",
        PageNumber: this.PageNumber,
        LineNumber: LineNumber,
        LastUpdate: this.$store.getters["page/Page"]["UpdateDT"],
      };
      await this.$store.dispatch("page/line_sendback", credentials).then(
        (response) => {
          this.fetch_table();
          //   console.log(response);
        },
        (error) => {
          console.log(error);
          switch (error) {
            case "BET_LINE_NOT_FOUND":
              this.ShowError("ไม่พบบรรทัดที่ต้องการตีกลับ");
              this.fetch_table();
              break;
            //     default:
            //       this.Sending = false;
            //       break;
          }
        }
      );
    },
    async OnResendLine(LineNumber){

    },
    OnP2Key(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        //not numaric
        if (charCode == 32) {
          this.$refs.BetTypeDlg.Show(this.Input.BtType);
        }
        // console.log(evt.key);
        if (evt.key == "/") this.ChangeInputBetType("1");
        if (evt.key == "*") this.ChangeInputBetType("2");
        if (evt.key == "-") this.ChangeInputBetType("3");

        if (evt.key == "." && this.Input.P1 == "") {
          if (this.canSwap(this.Input.BtType)) {
            // บน บนหน้า หมุน
            if (this.isTripple(this.Input.Num)) {
              this.ShowError(
                "ไม่อนุญาติให้ กลับ เลขตอง [" + this.Input.Num + "] ได้."
              );
            } else if (this.Input.Num.length == 3) {
              this.Input.Sign = "ก";
              this.Input.P1 = this.Input.P2;
              this.Input.P2 = "";
            } else {
              this.ShowError("ต้องเป็นเลข 3 ตัว ถึงจะกลับได้.");
            }
          } else {
            this.ShowError("ต้องเป็น 3บน 3บนหน้า หรือหมุน ถึงจะกลับได้.");
          }
        }
        if (evt.key == "+" && this.Input.P1 == "" && this.Input.P2 != "") {
          if (this.canMultiply(this.Input.BtType)) {
            // บน บนหน้า หมุน
            if (this.Input.Num.length == 3) {
              if (this.isTripple(this.Input.Num)) {
                this.ShowError(
                  "ไม่อนุญาติให้ x เลขตอง [" + this.Input.Num + "] ได้."
                );
              } else {
                this.Input.Sign = "x";
                this.Input.P1 = this.Input.P2;
                this.Input.P2 = "";
              }
            } else if (this.Input.Num.length == 2) {
              if (this.isDouble(this.Input.Num)) {
                this.ShowError(
                  "ไม่อนุญาติให้ x เลขเบิ้ล [" + this.Input.Num + "] ได้."
                );
              } else {
                this.Input.Sign = "x";
                this.Input.P1 = this.Input.P2;
                this.Input.P2 = "";
              }
            } else {
              this.ShowError("ต้องเป็นเลข 2 หรือ 3 ตัว ถึงจะคูณได้.");
            }
          } else {
            this.ShowError(
              "ต้องเป็น 3บน 2บน 2ล่าง บน+ล่าง คู่หน้า หรือคู่ถ่าง ถึงจะคูณได้."
            );
          }
        }
        evt.preventDefault();
      } else {
        if (charCode == 48 && this.Input.P2 == "") evt.preventDefault();
        // if (this.Input.Num.length >= this.getBetTypeLength(this.Input.BtType))
        //   evt.preventDefault();
        return true;
      }
    },
    ShowError(ErrorText) {
      this.$alert(ErrorText, "ผิดพลาด", "error").then(() => {});
    },
    OnPriceDel(e) {
      if (e.key == "Backspace") {
        if (this.Input.P2 == "") {
          if (this.Input.P1 == "") {
            this.Input.Sign = "";
            this.$refs.txtNum.focus();
            e.preventDefault();
          } else {
            this.Input.Sign = "";
            this.Input.P2 = this.Input.P1;
            this.Input.P1 = "";
            e.preventDefault();
          }
        }
      }
    },
    getPageStatusText(){
      const statusText = [
        "ลบ", //-1
        "รอส่ง",
        "ส่งแล้ว",
        "ตีกลับ",
        "ถูกรางวัล",
        "จ่ายแล้ว",
      ];
      return statusText[parseInt(this.$store.getters["page/Page"]["Status"])+1];
    },
    getPageStatusColor(){
      const statusColor = [
        "red",//"ลบ", //-1
        "light-blue darken-2",//"รอส่ง",
        "green darken-3",//"ส่งแล้ว",
        "red",//"ตีกลับ",
        "orange darken-4",//"ถูกรางวัล",
        "orange darken-4",//"จ่ายแล้ว",
      ];
      return statusColor[parseInt(this.$store.getters["page/Page"]["Status"])+1];
    },    
    getBetTypeColor(betType) {
      let BT = 0;
      switch (betType) {
        case "1": //บน
        case "4": //พวง
        case "6": //บน/น
        case "7": //บน/ก
        case "8": //บน/ท
        case "11": //บน/น
        case "12": //บน/ถ
        case "13": //ล.บ.
        case "15": //19/บ
        case "17": //ค.ต.
          BT = 0;
          break;
        case "2": //ล่าง
        case "5": //หมุน
        case "9": //ล่าง/น
        case "10": //ล่าง/ท
        case "14": //ล.ล
        case "16": //19/ล
          BT = 1;
          break;
        case "3":
          BT = 2;
          break;
        case "18": //3น.
        case "19": //3น.
        case "20": //3น/น
        case "21": //3น/น
        case "22": //3น/ท
        case "23": //3น/น
        case "24": //3น/ถ
        case "25": //3น.
        case "26": //ต/3น.
          BT = 3;
          break;
      }
      let text_color = [
        "indigo--text",
        "red--text",
        "teal--text",
        "deep-orange--text",
      ];
      return text_color[BT];
    },
    getBetLineStatusColor(Status) {
      switch (Status) {
        case "0": //ปกติ
          return "";
        case "1": //ลบ
          return "red--text";
        case "2": //แก้ไข
          return "indigo--text";
        case "3": //อั้น
          return "red--text";
        case "4": //ตีกลับ
          return "red--text";
        case "5": //ถูก
          return "pink white--text";
        case "6": //เลขเต็ม
          return "red--text";
        default:
          return "";
      }
    },
    getBetNumberColor(Status) {
      if (Status == "1" || Status == "4" || Status == "6") return "red--text";
      return "";
    },
    getDirectPrice(PriceText) {
      if (PriceText.indexOf(".") > -1)
        return PriceText.substr(0, PriceText.indexOf("."));
      if (PriceText.indexOf("x") > -1)
        return PriceText.substr(0, PriceText.indexOf("x"));
      return "";
    },
    getSign(PriceText) {
      if (PriceText.indexOf(".") > -1) return "ก";
      if (PriceText.indexOf("x") > -1) return "x";
      return "";
    },
    getSignColor(Sign) {
      if (Sign == "ก") return "red--text";
      return "";
    },
    getSwapPrice(PriceText) {
      if (PriceText.indexOf(".") > -1)
        return PriceText.substr(
          PriceText.indexOf(".") + 1,
          PriceText.length - PriceText.indexOf(".")
        );
      if (PriceText.indexOf("x") > -1)
        return PriceText.substr(
          PriceText.indexOf("x") + 1,
          PriceText.length - PriceText.indexOf("x")
        );
      return PriceText;
    },
    getPriceSumLine(PriceSum) {
      console.log("PriceSum");
      console.log(PriceSum);
      this.PriceSumLine += parseInt(PriceSum);
      return this.PriceSumLine;
    },
    getPriceColor(Price) {
      if (parseInt(Price) >= 1000) return "red--text";
      return "";
    },
    getStatusText(status) {
      const statusText = [
        "ปกติ", //0
        "ลบ",
        "แก้ไข",
        "อั้น",
        "ตีกลับ",
        "ถูก",
        "เลขเต็ม",
      ];
      return statusText[parseInt(status)];
    },
    getStatusColor(status) {
      const statusColor = [
        "red--text",
        "grey--text",
        "green--text",
        "red--text",
        "deep-purple--text",
        "black--text",
        "black--text",
        "black--text",
        "black--text",
        "black--text",
        "red--text",
      ];
      return statusColor[parseInt(status) + 1] + " pl-10";
    },
    add_click() {
      this.$router.push({
        path: "key",
        query: {
          line_id: this.LineID,
          agent_id: this.id,
          page_number: this.$route.query.page_number,
        },
      });
    },
    row_click: function(item) {
      this.selectedId = item.ShowLine;
      this.$set(item, "selected", true);
      //   console.log(item.id);
    },
    popup_menu: function(e, item) {
      e.preventDefault();
      this.$refs.PopupMenu1.Hide(e.clientX, e.clientY);
      this.$nextTick(() => {
        this.$refs.PopupMenu1.SetAgentID(this.AgentID);
        this.$refs.PopupMenu1.SetLineID(this.LineID);
        this.$refs.PopupMenu1.SetPageNumber(this.PageNumber);
        this.$refs.PopupMenu1.Show(
          item.LineNumber,
          this.$store.getters["page/Page"]["Status"],
          item.Status,
          item.ShowLine +
            ". " +
            this.getBetTypeName(item.BetTypeID) +
            " " +
            item.Number +
            " = " +
            item.PriceText
        );
        this.selectedId = item.ShowLine;
      });
    },
    printSection() {
      this.$htmlToPaper("printSection");
    },
  },
  data: () => ({
    Sending: false,

    LineID: "",
    AgentID: "",
    PageNumber: "",
    selectedId: -1,
    PriceSumLine: 0,
    Input: {
      ShowLine: 1,
      BtType: "1",
      Num: "",
      P1: "",
      P2: "",
      Sign: "",
    },
    LastLine: {
      ShowLine: 1,
      BtType: "1",
      Num: "",
      P1: "",
      P2: "",
      Sign: "",
    },
    // // right click
    // showMenu: false,
    // x: 0,
    // y: 0,
    id_column: "id_column_class",
    border_class: "border_class",
  }),
};
</script>

<style scoped>
::v-deep .v-input--is-focused .v-input__slot {
  border: 3px solid #0f0064 !important;
  /* border-bottom-color: rgba(0, 0, 0, 0.38) !important; */
}

::v-deep .id_column_class {
  background-color: rgb(55, 34, 95) !important;
  color: whitesmoke;
  /* text-align: center !important;  
  padding-top: 10px !important; */
  /* position:absolute;  */
  position: sticky !important;
  width: 4%;
  left: 0;
}
::v-deep .btLine {
  width: 55px !important;
}

::v-deep .v-text-field .v-input__control .v-input__slot {
  padding: 0 5px !important;
}

::v-deep .btNum input[type="number"],
::v-deep .btP2 input[type="number"] {
  -moz-appearance: textfield;
}

::v-deep .btNum input::-webkit-outer-spin-button,
::v-deep .btNum input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

::v-deep .btP2 input::-webkit-outer-spin-button,
::v-deep .btP2 input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

::v-deep .btNum input,
::v-deep .btP2 input {
  text-align: right !important;
}

.v-text-field >>> input {
  font-size: 0.75em !important;
}

::v-deep .id_column_sum_class {
  background-color: rgb(99, 0, 13) !important;
  color: whitesmoke;
  /* text-align: center !important;  
  padding-top: 10px !important; */
  /* position:absolute;  */
  position: sticky !important;
  width: 4%;
  left: 0;
}
/* ::v-deep table {
  table-layout : fixed;
} */

::v-deep table thead th:first-child {
  position: sticky !important;
  left: 0 !important;
  z-index: 3 !important;
  background: rgb(55, 34, 95) !important;
  color: whitesmoke !important;
  padding-top: 15px !important;
}

::v-deep table thead th:last-child {
  padding-left: 40px !important;
}

::v-deep table thead th {
  padding: 3px;
  position: sticky;
  top: 0;
  z-index: 1;
  width: 25vw;
  background: rgb(255, 255, 255);
  /* font-size: 1.0vw !important; */
  /* font-size: 1.4vmax !important; */
  font-size: 18px !important;
  /* font-size: 110% !important; */
}

::v-deep table {
  background-color: #f2f2f2;
}

::v-deep tr:nth-child(odd) {
  background-color: #edfafc;
}

::v-deep table tbody tr td {
  font-size: 0.75em !important;
  color: rgb(88, 88, 88);
  font-weight: bold;
}

::v-deep .border_class {
  border-right: 1px solid rgb(190, 197, 255);
}

::v-deep table thead tr th {
  font-size: 1em !important;
}

.h_num {
  width: 12%;
}
.h_betprice {
  width: 13%;
}
.h_status {
  width: 10%;
}
.h_betsum {
  width: 8%;
}
.h_pricesum {
  width: 8%;
}
.h_winsum {
  width: 8%;
}
.h_bettime {
  width: 15%;
}
.h_editby {
  width: 10%;
}
.c_P1 {
  width: 6%;
}
.c_P2 {
  width: 6%;
}
.c_Sign {
  width: 1%;
}
/* /deep/ tr.v-data-table__selected {
    background: lightgray !important;
    color: black;
    font-weight: bold;
  } */
</style>
